import React from "react";
import "./about.scss";
import bg from "./g.jpg";

const About = () => {
  return (
    <section id="about" className="about">
      <div className="container">
        <div className="row">
          <div className="aboutContainer">
            <div
              style={{ backgroundImage: `url(${bg})` }}
              className="aboutContainerImg bgImg"
            ></div>

            <div className="aboutContainerDetails">
              {/* <h1>
                Kepuasan Anda<span className="colorGrey"> Adalah Kebahagiaan Kami</span>
              </h1> */}
              <p>
              Bakso Karomah Abah atau dikenal sebagai 'Bakso Kang Dace', telah berdiri di Kota Tembilahan sejak 2013. Kami selalu mengutamakan citarasa dengan harga yang ramah di kantong.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
