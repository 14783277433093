import React, { useEffect, useState } from "react";
import "./home.scss";

const Home = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkDevice = () => {
      const mobile = window.innerWidth <= 768; // Ambang batas untuk mendeteksi mobile
      setIsMobile(mobile);
    };

    checkDevice(); // Check on mount
    window.addEventListener('resize', checkDevice); // Check on window resize

    return () => {
      window.removeEventListener('resize', checkDevice); // Cleanup on unmount
    };
  }, []);

  return (
    <section id="home" className="home">
      <video
        className="homeVideoIntro"
        autoPlay
        muted
        loop
      >
        <source
          src={isMobile ? "assets/home-intro-mobile.mp4" : "assets/home-intro.mp4"}
          type="video/mp4"
        />
        Your browser is not supported
      </video>
      <div className="homeDetails">
        <div className="container">
          <div className="row">
            <div className="slogan">
              <h1>Kepuasan Anda Adalah Kebahagiaan Kami.</h1><br />
              <p>- Bakso Karomah Abah -</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
