import React from "react";
import Form from "./Form/Form";

import { TiLocationOutline } from 'react-icons/ti';
import { FaMobileAlt } from 'react-icons/fa';
import { AiOutlineMail } from 'react-icons/ai';
import { BsChatSquare } from 'react-icons/bs';
import { AiFillFacebook } from 'react-icons/ai';
import { AiOutlineWhatsApp } from 'react-icons/ai';
import { FaInstagram } from 'react-icons/fa';
import "./contact.scss";

const Contact = () => {
  return (
    <section id="contact" className="contact">
      <div className="container">
        <h1 className="contactHeader">Kontak Kami</h1>
        <div className="contactAddress">
          <div className="contactAddressDetails">
            <h2><TiLocationOutline /></h2>
            <h1>Lokasi</h1>
            <p>Jl. Gerilya Parit 7</p>
          </div>
          <div className="contactAddressDetails">
            <h2><FaMobileAlt /></h2>
            <h1>WhatsApp</h1>
            <p>0821-1823-4808</p>
          </div>
          <div className="contactAddressDetails">
            <h2><AiFillFacebook /></h2>
            <h1>Facebook</h1>
            <p>Bakso Karomah Abah</p>
          </div>
          <div className="contactAddressDetails">
            <h2><FaInstagram /></h2>
            <h1>Instagram</h1>
            <p>@baksokaromahabah</p>
          </div>
        </div>

        {/* <Form /> */}
      </div>
    </section>
  );
};

export default Contact;
