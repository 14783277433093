export const mie_bakso = [
  {
    id: 1,
    name: "Mie Bakso Kecil",
    price: 9000,
    imageUrl:
    "/assets/mie_bakso.png",
  },
  {
    id: 2,
    name: "Mie Bakso Besar",
    price: 13000,
    imageUrl:
    "/assets/mie_bakso_besar.jpg",
  },
  {
    id: 3,
    name: "Bakso Kosong Besar",
    price: 13000,
    imageUrl:
    "/assets/bakso_kosong_besar.jpg",
  },
];
