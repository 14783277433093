export const mie_ayam = [
  {
    id: 1,
    name: "Mie Ayam",
    price: 9000,
    imageUrl:
    "/assets/mie_ayam.jpg",
  },
  {
    id: 2,
    name: "Mie Ayam Bakso Kecil",
    price: 11000,
    imageUrl:
    "/assets/mie_ayam_bakso_kecil.jpg",
  },
  {
    id: 3,
    name: "Mie Ayam Bakso Besar",
    price: 14000,
    imageUrl:
      "/assets/mie_ayam_bakso_besar.jpg",
  },
];
