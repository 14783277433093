import React from "react";

import "./footer.scss";
import bg from './l.jpg';


const Footer = () => {
  return (
    <footer
      className="footer bgImg bgImgFixed"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="container">
        <div className="footerInfo">
          <div className="footerDetails">
            <h1>Bakso Karomah Abah</h1>
            <a href="#home">
              <p>Home</p>
            </a>
            <a href="#about">
              <p>Tentang</p>
            </a>
          </div>
          <div className="footerDetails">
            <h1>Informasi</h1>
            <a href="#menu">
              <p>Menu</p>
            </a>
            <a href="#contact">
              <p>Kontak</p>
            </a>
          </div>
          <div className="footerDetails" >
            <iframe title="Lokasi kami" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.7489348935483!2d103.13511407472342!3d-0.33790599965877266!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e2841a5d5726f99%3A0xe3ed7bd66b665682!2sBakso%20Karomah%20Abah!5e0!3m2!1sen!2sid!4v1717513246836!5m2!1sen!2sid"></iframe>
          </div>
        </div>
        <p className="license">copyright 2024 &#169; Bakso Karomah Abah</p>
      </div>
    </footer>
  );
};

export default Footer;
